import * as CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : '12345';
export const encrypt = (plainText) => {
  const cipherText = CryptoJS.AES.encrypt(plainText, secretKey).toString();
  return cipherText;
};

export const decrypt = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
};


export function formatToFixed(value, decimals = 2) {
  if (isNaN(value)) {
    throw new Error("Input must be a valid number");
  }

  const numericValue = Number(value);

  // Check if the number has decimals
  if (numericValue % 1 !== 0) {
    return numericValue.toFixed(decimals);
  }

  // If no decimals, return as-is
  return numericValue.toString();
}