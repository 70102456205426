import { FormGroup, Input, Label } from 'reactstrap';
import React from 'react';
import { v4 as uuid } from 'uuid';

export const WrappedInput = React.forwardRef((props, ref) => {
  if (props.type === 'text' || props.type === 'number') {
    return (
      <FormGroup floating>
        <Input innerRef={ref} {...props} placeholder={' '} />
        <Label>{props.placeholder}</Label>
      </FormGroup>
    );
  } else {
    return <Input innerRef={ref} {...props} />;
  }
});
