import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';

import TicketReducer from './apps/tickets/TicketSlice';

import SiteSliceReducer from './apps/sites/SiteSlice';
import UserProfileReducer from './apps/userProfile/UserProfileSlice';
import ProjectSlice from './apps/project/ProjectSlice';
import StatisticsReducer from './apps/statistics/StatisticsSlice';
import VendorSlice from './apps/vendor/VendorSlice';
export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,

    ticketReducer: TicketReducer,
    siteSliceReducer: SiteSliceReducer,
    userpostsReducer: UserProfileReducer,
    projectReducer: ProjectSlice,
    statisticsReducer: StatisticsReducer,
    VendorSliceReducer: VendorSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
