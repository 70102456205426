import React from 'react';
import siteSelectionBg from '../assets/images/siteSelectionBg.png';
import lock from '../assets/images/lock.png';
import mapimg from '../assets/images/mapimg.png';
import { Button } from 'reactstrap';
import basemap from '../assets/images/basemap.png';
import valuationLayerIcon from '../assets/images/valuation-layer-1.jpg';

import SubHeader from './sub-header';
import CopyRightComponent from './copyright';
import { useNavigate } from 'react-router-dom';
const SitesSelection = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="valuation-hero-area">
        <SubHeader />
        <div className="container">
          <div className="valuation_main">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <div className="text-white">
                  <h2 className="wow fadeInUp contactUsTitle" data-wow-delay=".4s">
                    Site Selection and Location Strategy
                  </h2>
                  <p className="wow fadeInUp contactUsDesc" data-wow-delay=".6s">
                    Here We Define the Most important site Selection and Location Strategy
                  </p>
                  <button
                    onClick={() => {
                      
                      // navigate('/sitesuitability');
                    }}
                   
                    className="px-6 py-[0.7rem] z-50 bg-[#00ABFF]  text-[#FFFFFF] rounded-lg text-[14px]
                    hover:bg-white  hover:text-[#00ABFF] transition duration-300 ease-in-out"
                  >
                    Get Started (Coming Soon)
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex mt-5 flex-col overflow-hidden">
        <div className="p-4 md:p-10 ">
          <div className="w-full min-h-[300px]">
            {/* <div className="relative mt-10">
              <h1 className="md:absolute text-2xl sm:text-4xl md:text-[60px] font-bold w-full sm:w-[500px] md:w-[750px] md:leading-[3rem] top-0 left-5 md:left-0"></h1>
              <p className="md:absolute text-[#071A34] left-5 md:top-10 md:left-[33rem] w-4/5 md:w-[350px] text-sm md:text-base mt-4 md:mt-5"></p>
              <p className="md:absolute text-[#071A34] left-5 md:top-16 md:left-[33rem] w-4/5 md:w-[350px] text-sm md:text-base mt-4"></p>
            </div> */}

            <div className="relative">
              <img src={siteSelectionBg} alt="heroimage" className="w-full" />
              {/* <div className="md:absolute z-50 md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 xl:-translate-y-2 md:-translate-y-1/2 absolute translate-y-1/2 left-24 top-16">
                <button
                  onClick={() => {
                    //   router.push("/sitesuitability");
                  }}
                  className="px-6 py-[0.7rem] z-50 bg-[#00ABFF]  text-[#FFFFFF] rounded-lg text-[14px]
                    hover:bg-white  hover:text-[#00ABFF] transition duration-300 ease-in-out"
                >
                  Get Started
                </button>
              </div> */}
              <div className=" lg:block mt-3   lg:absolute right-10 top-10 lg:top-0 xl:top-20 md:top-24 rounded-[2rem] p-4 xl:p-2 w-full lg:w-[250px] md:min-h-[250px] lg:min-h-[100px] xl:min-h-[200px] bg-gray-600 bg-opacity-80  border border-2  ">
                <h1 className="text-white text-[20px] font-[600]">AI-Powered Site Analysis</h1>
                <p className="text-white text-[12px] mt-3">
                  Harness the power of AI to analyze vast datasets, including demographic trends, market conditions, and
                  competitive landscapes, to find the best possible site for your needs.
                </p>
              </div>

              <div className="mt-3 lg:block lg:absolute bottom-20 md:bottom-[0.2rem] xl:bottom-[1.3rem] right-10 rounded-[2rem] p-4 lg:p-2 xl:p-0 w-full lg:w-[250px] xl:min-h-[200px] border bg-gray-600 bg-opacity-80 border-2 border-gray-300">
                <h1 className="text-[#FFFFFF] text-[20px] font-[600]">Spatial Intelligence</h1>
                <p className="text-[#FFFFFF] text-[12px] mt-5">
                  Utilize advanced spatial analysis to evaluate geographic factors such as proximity to key
                  infrastructure, accessibility, and environmental considerations.
                </p>
                <img src={basemap} alt="heroimage" layout="responsive" className="mt-3" />
              </div>
              <div className="mt-3 lg:block  lg:absolute bottom-5 left-[2rem] rounded-3xl p-5 w-full lg:w-[320px] md:min-h-[100px] bg-gray-600 bg-opacity-80 border border-2 border-gray-300">
                <h1 className="text-[#FFFFFF] text-[20px] font-[600]">Link to Map</h1>
                <p className="text-[#FFFFFF] text-[12px] mt-1">
                  Here we define the most important site selection and location strategy.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col items-center justify-center w-full my-10 px-4 md:px-0">
        <h1 className="text-left text-[#212121] font-[700] text-base md:text-[40px]">
          AI Algorithm
        </h1>
        <p className="text-[#0D261B] text-center md:text-[20px] mt-5 w-full md:w-[500px]">
          Please answer the following question for AI to show you your desired
          locations
        </p>
      </div> */}
        {/* <div className="px-4 md:px-0">
        <MultiStepForm />
      </div> */}
        <div className="mx-4">
          <div className="flex flex-col lg:flex-row w-full justify-between  md:space-x-16  md:px-20 py-5  bg-[#0D48BA] mt-20 rounded-[2rem] items-center mb-5 ">
            <div className="flex flex-col w-full md:w-[600px]">
              <h1 className="text-[#FFFFFF] text-left md:leading-[3rem] p-2 font-bold md:font-[500] text-base md:text-[40px]">
                Link to Map for <br /> Site Selection <br /> Purposes
              </h1>
              <p className="text-[#FFFFFF] text-left md:text-[18px] leading-7 mt-5 p-2">
                Selecting the right site is critical to the success of any project. Our site selection process harnesses
                cutting-edge spatial intelligence and AI-driven analytics to pinpoint the most strategic locations for
                your commercial, residential, or industrial projects. By integrating Geographic Information Systems
                (GIS) with layered data overlays—such as demographic patterns, infrastructure accessibility, and
                environmental considerations—we provide a comprehensive analysis that highlights the most feasible and
                optimal sites. This combination of technology and data ensures your site is primed for success.
              </p>

              <div>
                <button
                  onClick={() => {
                    //   router.push("/sitesuitability");
                  }}
                  className="px-6 py-[0.7rem] z-50 bg-[#00ABFF]  text-[#FFFFFF] rounded-lg text-[14px]
                    hover:bg-white mb-5 hover:text-[#00ABFF] transition duration-300 ease-in-out"
                >
                  Start Selection
                </button>
              </div>
              {/* <div className="hidden md:block  flex-col justify-center md:flex-row w-full md:w-[1100px] gap-5 blur-[25px]  items-center ml-[3rem]  md:mt-[3rem]">
            <button className="bg-[#d1c7c7] text-[#0D48BA] px-6 py-[1.5rem] rounded-full md:w-[320px]">
              Learn More
            </button>
            <button className="bg-[#d1c7c7] text-[#0D48BA] px-6 py-[1.5rem] rounded-full md:w-[320px]">
              Learn More
            </button>
            <button className="bg-[#d1c7c7] text-[#0D48BA] px-6 py-[1.5rem] rounded-full md:w-[320px]">
              Learn More
            </button>
          </div> */}
            </div>

            <div className="flex items-center justify-center gap-2 mt-8  md:mt-5  mr-5">
              {/* <div className="relative">
                <p className="text-[#FFFFFF] font-[400] tracking-wider text-[14px] absolute md:right-[-25rem] ml-10 top-[-11rem]  ">
                  Follow us on
                </p>
              </div> */}

              <img src={lock} className="w-[120px] sm:w-[100px] md:w-full " />
              <img src={mapimg} alt="heroimage" className="w-[140px] sm:w-[100px] md:w-full " />
            </div>
          </div>
        </div>

        {/* <div className="flex flex-col md:flex-row w-full justify-between px-4 md:px-20 py-5 mt-[7rem] rounded-2xl items-center">
          <div className="flex flex-col w-full md:w-[400px]">
            <h1 className="text-[#212121] text-left leading-[3rem] font-[400] text-base md:text-[30px]">
              Map Based Input
            </h1>
            <p className="text-[#212121] text-left md:text-[16px]">
              Parameters for site selection and location strategy based on respective property
            </p>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-10 mt-5 md:mt-0">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#212121] text-left leading-[3rem] font-[400] text-base md:text-[30px]">900+</h1>
              <p className="text-[#212121] text-left md:text-[12px]">
                Landowners <br />
                earning from clean energy
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h1 className="text-[#212121] text-left leading-[3rem] font-[400] text-base md:text-[30px]">50</h1>
              <p className="text-[#212121] text-left md:text-[12px]">
                Projects in Development, <br />
                12 GW+ pipeline
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h1 className="text-[#212121] text-left leading-[3rem] font-[400] text-base md:text-[30px]">20</h1>
              <p className="text-[#212121] text-left md:text-[12px]">
                Landowners <br />
                earning from clean energy
              </p>
            </div>
          </div>
        </div> */}

        {/* <div className="flex flex-col w-full justify-center items-center gap-5 p-5 mt-10 bg-[#1DAEFF]">
        <h1 className="text-white text-center leading-[3rem] font-[400] text-base md:text-[30px]">
          What our customer <br /> says About Us
        </h1>
        <div className="flex relative justify-center">
          <div className="hidden md:block">
            <div className="top-[-50px] right-[6rem] w-14 h-14  relative">
              <Image src={customer1} alt="heroimage1" layout="responsive" />
            </div>
            <div className="top-[1rem]  right-[14.5rem] w-8 h-8 relative">
              <Image src={customer2} alt="heroimage2" layout="responsive" />
            </div>
            <div className="top-16 w-28 right-[5rem] h-28 relative">
              <Image src={customer3} alt="heroimage3" layout="responsive" />
            </div>
            <div className="top-[3rem] w-14 h-14 right-[15rem] relative">
              <Image src={customer4} alt="heroimage4" layout="responsive" />
            </div>
          </div>
          <div className="w-full max-w-[700px] ">
            <Testimonial />
          </div>
          <div className="hidden md:block">
            <div className="top-[-3em] w-20 h-20 right-[-13rem] relative">
              <Image src={customer5} alt="heroimage5" layout="responsive" />
            </div>
            <div className="top-[-2rem] w-12 h-12  left-[4rem] relative">
              <Image src={customer6} alt="heroimage6" layout="responsive" />
            </div>
            <div className="top-[2rem] w-14 h-14  left-[4rem] relative">
              <Image src={customer7} alt="heroimage7" layout="responsive" />
            </div>
            <div className="top-[1rem] w-36 h-36  left-[10rem] relative">
              <Image src={customer8} alt="heroimage8" layout="responsive" />
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className="px-4 md:px-0">
       <LeaseTeam/>
      </div>
      <div className="my-10 px-4 md:px-0">
        <ContactTeam />
      </div> */}
      </div>
      <CopyRightComponent />
    </div>
  );
};

export default SitesSelection;
