import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../services/axio-service';

const initialState = {
  tickets: {},
  currentFilter: 'total_tickets',
  isUpdated: false,  
};

export const TicketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
    },
    updateTicket: (state, action) => {
      const { id, status } = action.payload;
      const ticketIndex = state.tickets.data.findIndex((ticket) => ticket.id === id);
      if (ticketIndex !== -1) {
        state.tickets.data[ticketIndex].status = status;
        state.isUpdated = true; // Set flag when a ticket is updated
      }
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    resetUpdateFlag: (state) => {
      state.isUpdated = false; // Reset the flag
    },
  },
});

export const { getTickets, updateTicket, setVisibilityFilter, resetUpdateFlag } = TicketSlice.actions;

// Fetch Tickets
export const fetchTickets = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('admindashboard/tickets/');
    dispatch(getTickets(response.data));
  } catch (err) {
    console.error('Error fetching tickets:', err);
  }
};

// Update Ticket
export const updateTicketStatus = (ticketData) => async (dispatch) => {
  try {
    const response = await axiosInstance.patch('admindashboard/tickets/', ticketData);
    if (response.status === 200) {
      dispatch(updateTicket(ticketData));
    }
  } catch (err) {
    console.error('Error updating ticket status:', err);
  }
};

export default TicketSlice.reducer;
