import React, { useState, useEffect, useContext, createContext } from 'react';
import { AUTH_CONSTANTS, URL_CONSTANTS } from '../store/constant';
import axios from 'axios';
import axiosInstance from '../services/axio-service';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(false);
  const [isSuperUser, setSuperUser] = useState(false);
  const [isVendorApproved, setIsVendorApproved] = useState(false);

  // Wrap any  methods we want to use making sure ...
  // ... to save the user to state.

  const parseJwt = () => {
    try {
      return JSON.parse(window.atob(localStorage.getItem(AUTH_CONSTANTS.ACCESS_TOKEN).split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    try {
      const jwtPayload = parseJwt();
      if (jwtPayload) {
        if (Date.now() <= jwtPayload.exp * 1000) {
          setUser(true);
          let is_superuser = localStorage.getItem('is_superuser');
          let is_vendor_approved = localStorage.getItem('is_vendor_approved');

          if (is_superuser == 'true') {
            setSuperUser(true);
            
          }
          if (is_vendor_approved == 'true') {
            setIsVendorApproved(true);
          }
          return;
        }
        signOut();
      }
    } catch (e) {
      setUser(false);
      clearToken();
    }
  }, []);

  useEffect(() => {
    try {
      if (user === false) return;

      const myInterval = setInterval(function () {
        const jwtPayload = parseJwt();
        if (jwtPayload) {
          if (Date.now() > jwtPayload.exp * 1000) {
            clearInterval(myInterval);
            clearToken();
          }
        } else {
          clearInterval(myInterval);
          clearToken();
        }
      }, 1000);
    } catch (e) {
      clearToken();
    }
  }, [user]);

  const saveToken = (token) => {
    localStorage.setItem(AUTH_CONSTANTS.ACCESS_TOKEN, token.access);
    localStorage.setItem(AUTH_CONSTANTS.REFRESH_TOKEN, token.refresh);
    localStorage.setItem('user_id', token.user_id);

    const user = {
      name: token?.fullName,
      user_type: token.user_type,
      business_name: token.business_name,
    };
    localStorage.setItem(AUTH_CONSTANTS.USER_DATA, JSON.stringify(user));
    localStorage.setItem('is_superuser', token.is_superuser);
    localStorage.setItem('is_vendor_approved', token.is_vendor_approved);

    setUser(true);
    if (token.is_superuser) {
      setSuperUser(true);
    }
    if (token.is_vendor_approved) {
      setIsVendorApproved(true);
    }
  };

  const clearToken = () => {
    localStorage.removeItem(AUTH_CONSTANTS.ACCESS_TOKEN);
    localStorage.removeItem(AUTH_CONSTANTS.USER_DATA);
    localStorage.removeItem('user_id');

    localStorage.removeItem('is_superuser');
    localStorage.removeItem('is_vendor_approved');

    setUser(false);
    setSuperUser(false);
    setIsVendorApproved(false);
  };

  const signIn = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${URL_CONSTANTS.WEB_SERVERURL}/accounts/login/`, data)
        .then((response) => {
          saveToken(response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    });
  const signUp = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${URL_CONSTANTS.WEB_SERVERURL}/accounts/signup/`, data)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    });

  const signOut = () =>
    new Promise((res) => {
      setSuperUser(false);
      axiosInstance
        .get(`${URL_CONSTANTS.WEB_SERVERURL}/accounts/logout/`)
        .then((r) => {
          localStorage.removeItem('user-email');
          res();
        })
        .finally(() => {
          clearToken();
          setUser(false);
        });
    });

  const sendPasswordResetEmail = (data) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${URL_CONSTANTS.WEB_SERVERURL}/accounts/password/reset/`, data)
        .then((response) => {
          setUser(false);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    });

  const confirmPasswordReset = (code, password, confirmPassword) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${URL_CONSTANTS.WEB_SERVERURL}/accounts/password/reset/verified/`, {
          code,
          password,
        })
        .then((response) => {
          setUser(false);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    });

  const PasswordChange = (oldPassword, newPassword) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${URL_CONSTANTS.WEB_SERVERURL}/accounts/passwordchange/`, {
          oldPassword,
          newPassword,
        })
        .then((response) => {
          setUser(false);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          return reject(error);
        });
    });

  // Return the user object and auth methods
  return {
    user,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    clearToken,
    PasswordChange,
    isSuperUser,
    isVendorApproved,
  };
}
