import { useEffect, useState } from 'react';
import { Button, Col, Form, FormFeedback, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { WrappedInput } from './WrappedInput';
import { toast } from 'react-toastify';
import Emitter from './Emitter';
import axiosInstance from '../services/axio-service';
function CustomerService() {
  const { register, setError, handleSubmit, formState, reset, trigger, watch, setValue } = useForm();
  const { errors } = formState;
  const userEmail = localStorage.getItem('user-email');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await axiosInstance.post(`/admindashboard/tickets/`, data);
      toast.success('Thanks for your feedback. We will get back to you soon.');
      Emitter.emit('close_customer_service');
    } catch (e) {
      console.log(e);
      toast.error(e?.response?.data?.error || e?.error || e?.data?.error || 'Error!');
    } finally {
      setIsLoading(false);
    }
  };

  let email = watch('email');
  let ticketDescription = watch('ticket_description');

  // Trigger validation on field change
  useState(() => {
    if (email !== undefined) trigger('email');
    if (ticketDescription !== undefined) trigger('ticket_description');
  }, [email, ticketDescription]);
  useEffect(() => {
    if (userEmail) {
      setValue('email', userEmail);
    }
  }, [userEmail]);
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className="  p-10">
        <div className="mb-5">
          <h5 className="text-center mb-1">Customer Service</h5>
          <p>Please fill out the form below to submit a ticket</p>
        </div>

        {/* Email Field */}
        <Row className="mb-3">
          <Col>
            <label className=" mb-2 font-bold ">Email</label>
            <WrappedInput
              disabled={userEmail ? true : false}
              type="email"
              name="email"
              placeholder="Please enter registered email"
              id="email"
              className="form-control font-[14px]"
              invalid={!!errors?.email}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Za-z][A-Za-z0-9._%+-]*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                  message: 'Please enter a valid email address',
                },
              })}
            />
            {errors?.email && <FormFeedback>{errors?.email?.message}</FormFeedback>}
          </Col>
        </Row>

        {/* Ticket Description Field */}
        <Row className="mb-3">
          <Col>
            <label className=" mb-2 font-bold ">Ticket Description</label>
            <WrappedInput
              type="textarea"
              name="ticket_description"
              className="form-control font-[14px]"
              placeholder="Please describe your issue (min 10 characters)"
              id="ticket_description"
              invalid={!!errors?.ticket_description}
              {...register('ticket_description', {
                required: 'Ticket description is required',
                minLength: {
                  value: 10,
                  message: 'Description must be at least 10 characters long',
                },
              })}
            />
            {errors?.ticket_description && <FormFeedback>{errors?.ticket_description?.message}</FormFeedback>}
          </Col>
        </Row>

        <div className="text-center mt-1">
          <Button type="submit" className="w-100" style={{ background: '#00ABFF', border: 'none' }}>
            {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
            Submit Ticket
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CustomerService;
