import { useAppState } from '../../hooks/useAppState';

import generateReportIcon from '../../assets/images/project-type.svg';
import statsReportIcon from '../../assets/images/stats-report.svg';
import StepActions from './step-actions';
import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';

import axiosInstance, { axiosUnSecureInstance } from '../../services/axio-service';
import { toast } from 'react-toastify';
import LoadingOverlay from 'react-loading-overlay-ts';
import { restructureCommercialData, restructureGroceryData } from '../../utils/ObjectUtils';
import { useRemainingReportState } from '../../hooks/useRemainingReportSate';

function GenerateReportStep({ step, setStep }) {
  const [stepValues, setStepValues] = useAppState();
  const [isLoading, setIsLoading] = useState(false);
  console.log('stepValues', stepValues);

  const [generatedReportData, setGeneratedReportData] = useState(null);

  const [new_monthly_sales_projections, set_new_monthly_sales_projections] = useState(null);
  const [new_monthly_volume_projections, set_new_monthly_volume_projections] = useState(null);
  const [new_yearly_sales_projections, set_new_yearly_sales_projections] = useState(null);
  const [new_yearly_volume_projections, set_new_yearly_volume_projections] = useState(null);

  // const [remainingReports, setRemainingReport] = useState('');
  const { remainingReports, setRemainingReport } = useRemainingReportState();

  const { user } = useAuth();

  const submit = (data) => {
    // stepValues.stepStatus[step] = 'done';
    // data.stepStatus = [...stepValues.stepStatus];

    let req = { ...stepValues };
    let serviceUrl = req.facType == 'grocery' ? `/GroceryViewSet/` : `/CommercialViewSet/`;

    // all leads
    let newLead = stepValues.leadValue;
    // selected Leads
    let leadsArray = stepValues.leadsDetailsArr;

    // return array of selected leadsDetailsArr
    let filteredLeadObjects = newLead
      ?.filter((lead) => leadsArray?.includes(lead.name))
      .map((lead) => ({
        business_type_id: lead.id,
        isLeads: true,
        isSendReport: true,
      }));

    req.business_leads = filteredLeadObjects;
    let formData = new FormData();
    let newReq;
    if (req.facType == 'grocery') {
      newReq = restructureGroceryData(req);
    } else {
      newReq = restructureCommercialData(req);
    }

    if (req.facType == 'grocery') {
      formData.append('name', newReq.name);
      formData.append('project_type', newReq.project_type);
      formData.append('facility_type', newReq.facility_type);
      formData.append('development_type', newReq.development_type);
      formData.append('business_leads', JSON.stringify(newReq.business_leads));
      formData.append('grocery_sites', JSON.stringify(newReq.grocery_sites));
    } else {
      formData.append('name', newReq.name);
      formData.append('project_type', newReq.project_type);
      formData.append('facility_type', newReq.facility_type);
      formData.append('development_type', newReq.development_type);
      formData.append('business_leads', JSON.stringify(newReq.business_leads));
      formData.append('addons', JSON.stringify(newReq.addons));
      formData.append('retail_unit', JSON.stringify(newReq.retail_unit));
      formData.append('devStrategyAddon', JSON.stringify(newReq.devStrategyAddon));
      formData.append('strategies', JSON.stringify(newReq.strategies));
      formData.append('industrial_sites', JSON.stringify(newReq.industrial_sites));
    }

    if (newReq.siteImages && newReq.siteImages.length > 0) {
      for (let i = 0; i < newReq.siteImages.length; i++) {
        formData.append('siteImages', newReq.siteImages[i]);
      }
    }

    if (newReq.interiorSitePlans && newReq.interiorSitePlans.length > 0) {
      for (let i = 0; i < newReq.interiorSitePlans.length; i++) {
        formData.append('interiorSitePlans', newReq.interiorSitePlans[i]);
      }
    }

    if (newReq.exteriorSitePlans && newReq.exteriorSitePlans.length > 0) {
      for (let i = 0; i < newReq.exteriorSitePlans.length; i++) {
        formData.append('exteriorSitePlans', newReq.exteriorSitePlans[i]);
      }
    }

    setIsLoading(true);
    axiosInstance
      .post(serviceUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(function (response) {
        let responseObj = response.data;

        setGeneratedReportData(responseObj);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.error('error', error, error?.response,  );
        if (error?.response.data.error === 'Recharge the account to make this request') {
          toast.error(error?.response.data.error);
        } else {
          toast.error('Error occured while generating report check values and try again');
        }
    
      });
  };

  const [allApiCallsComplete, setAllApiCallsComplete] = useState(false);

  useEffect(() => {
    const getGet = async () => {
      try {
        const custom_data = generatedReportData.custom_data;

        const objArray = [
          custom_data.new_monthly_sales_projections,
          custom_data.new_monthly_volume_projections,
          custom_data.new_yearly_sales_projections,
          custom_data.new_yearly_volume_projections,
        ];

        // Loop through objArray and send requests
        for (let i = 0; i < objArray.length; i++) {
          const obj = objArray[i];
          // Delay each request by 10 seconds
          await new Promise((resolve) => setTimeout(resolve, 10000));

          try {
            const res = await axiosInstance.post('/chatgpt/', obj);
            console.log('resss', res);

            let errorMessage = res?.data?.response?.match(/'message': '([^']+)'/);
            if (errorMessage) {
              throw new Error(errorMessage[1]);
            }

            const splitRes = res.data.response.split('\n\n');

            // Create newObj based on splitRes
            const newObj = {
              keyFinding: splitRes[0],
              TrendsObserved: splitRes[1],
              PotentialMarketImplications: splitRes[2],
            };

            // Set state based on index
            switch (i) {
              case 0:
                set_new_monthly_sales_projections(newObj);
                break;
              case 1:
                set_new_monthly_volume_projections(newObj);
                break;
              case 2:
                set_new_yearly_sales_projections(newObj);
                break;
              case 3:
                set_new_yearly_volume_projections(newObj);
                break;
              default:
                break;
            }
          } catch (error) {
            console.error('Error occurred:', error);
            toast.error(error.message);
            throw error; // Throw the dsfdferror to exit the loop
          }
        }

        // Update state indicating that all API calls have been made
        setAllApiCallsComplete(true);
      } catch (error) {
        console.error('An error occurred during the API calls:', error);

        setAllApiCallsComplete(false);
        setGeneratedReportData(null);
      } finally {
        // setIsLoading(false);
      }
    };

    if (generatedReportData !== null) {
      getGet();
    }
  }, [generatedReportData]);
  useEffect(() => {
    let generateReport = async () => {
      let newCustomObj = {
        ...generatedReportData.custom_data,
        new_monthly_sales_projections: new_monthly_sales_projections,
        new_monthly_volume_projections: new_monthly_volume_projections,
        new_yearly_sales_projections: new_yearly_sales_projections,
        new_yearly_volume_projections: new_yearly_volume_projections,
      };

      let finalObj = {
        newCustomObj,
        development_type: generatedReportData.development_type,
        report_path: generatedReportData.report_path,
        valuation: generatedReportData.valuation,
        user: generatedReportData.user,
      };
      try {
        let responseObj = await axiosInstance.post('/generate-pdf/', finalObj);
        // stepValues.stepStatus[step] = 'done';
        // data.stepStatus = [...stepValues.stepStatus];

        setStepValues({
          ...stepValues,

          ...{ reportPath: responseObj.data.response.report_path },
        });

        const res = await axiosInstance.get('subscribed-product/');

        setRemainingReport(res?.data?.plan);
      } catch (error) {
        console.log('errr', error);
        setIsLoading(false);
        setAllApiCallsComplete(false);
        setGeneratedReportData(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (allApiCallsComplete) {
      generateReport();
    }
  }, [allApiCallsComplete]);

  const handleBeforeUnload = (event) => {
    const message = 'Are you sure you want to leave? Changes you made may not be saved.';
    event.returnValue = message;
    return message;
  };

  useEffect(() => {
    // Attach the event listener to the beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Evaluating Results">
        <section>
          <div className="wizard-finish">
            <div className="d-flex   align-items-center justify-content-between">
              <div className="  d-flex">
                <img src={generateReportIcon} />
                <h2 className="section-heading">Generate Report</h2>
              </div>
              <div>
                {user && (
                  <h5 className=" " style={{ color: '#0497fe' }}>
                    {' '}
                    {remainingReports} Reports Remaining{' '}
                  </h5>
                )}
              </div>
            </div>
            {/* <h5 className=" text-start" style={{marginTop:"-1.5rem",color:"#0497fe",marginLeft:"2.2rem"}}> {remainingReports} Reports Remaining </h5> */}

            <div className="stats-wrapper">
              <div className="stats-image">
                <img src={statsReportIcon} />
              </div>
              {/* <div className="stats-value">
              {(stepValues.projType === 'valuation' || stepValues.projType === 'projectval') && (
                <>
                  <h3>Estimated Market Value</h3>
                  <h2>${numberWithCommas(stepValues.valuation)}</h2>
                </>
              )}

              {(stepValues.projType === 'projection' || stepValues.projType === 'projectval') && (
                <>
                  <h3>Monthly Projected Sales</h3>
                  <h2>${numberWithCommas(stepValues.forecast)}</h2>
                </>
              )}
            </div> */}
            </div>
            <p>
              Thank you for using our evaluation service. We want to remind you that the evaluated value you received
              was based solely on the input you provided. Please keep in mind that the actual value may vary due to a
              number of factors beyond our control.
            </p>
            {/* <p>
              If you require a more detailed analysis from one of our experts, we encourage you to click on the
              <strong>"Generate Full Report"</strong> button. Please note that generating a report will provide you with
              a comprehensive understanding of the factors that affect the value, and a more accurate evaluation. Our
              team of professionals is committed to providing you with the most accurate and reliable information
              possible.
            </p> */}
            <p>Thank you for using our service, and we hope that we can assist you again in the future.</p>
          </div>

          <StepActions
            disablePrevious={stepValues?.reportPath || isLoading ? true : false}
            disableNext={true}
            showNext={false}
            showFinish={user && Number(remainingReports) != 0 ? true : false}
            showResult={user}
            handlePrevious={() => {
              setStep(step - 1);
            }}
            handleGenerateReportClick={() => {
              window.open(stepValues.reportPath, '_blank', 'noreferrer');
            }}
            reports={remainingReports}
            submit={submit}
            user={user}
            reportPath={stepValues?.reportPath}
            isLoading={isLoading}
          />
        </section>
      </LoadingOverlay>
    </div>
  );
}

export default GenerateReportStep;
