import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import Main from './pages/main';
import Header from './pages/header';
import ContactUsComponent from './pages/contact-us';
import GettingStarted from './pages/getting-started';
import ProjectionPage from './pages/projection';
import ValuationPage from './pages/valuation';
import FormWizard from './pages/form-wizard';
import React, { lazy } from 'react';

import Emitter from './components/Emitter';
import LoginSignUpBox from './components/LoginSignUp';
import { toast, ToastContainer } from 'react-toastify';
import { ProvideAuth, useAuth } from './hooks/useAuth';
import ResetPassword from './pages/reset-password';
import SubmittedReports from './pages/submitted-reports';
import PrivateRoute from './components/PrivateRoute';
import { AppProvider } from './hooks/useAppState';
import PrivacyPolicy from './pages/privacy-policy';
import Payment from './pages/payment';
import CheckoutPage from './pages/checkout';
import Leads from './pages/leads';
import Userprofile from './pages/userprofile';
import Vendor from './pages/Vendor';
import LeadsDetail from './pages/LeadsDetail';
import NotFound from './components/NotFound';
import HowToUse from './pages/how-to-use';
import VendorPage from './pages/VendorPage';
import Otp from './pages/otp';
import { RemainingReportProvider } from './hooks/useRemainingReportSate';
import useMobileDetection from './hooks/useMobileDetection';
import MobileModel from './components/model/MobileModel';
import Team from './pages/team';
import SitesSelection from './pages/SitesSelection';
import DeleteUser from './pages/delete-user';
import SiteSuitability from './pages/SiteSuitability';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { baselightTheme } from './theme/DefaultColors';

import Loadable from './layouts/full/shared/loadable/Loadable';

const FullLayout = Loadable(lazy(() => import('./layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('./layouts/blank/BlankLayout')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('./views/dashboard/Modern')));
const ModernDash = Loadable(lazy(() => import('./views/dashboard/Modern')));
const Tickets = Loadable(lazy(() => import('./views/apps/tickets/Tickets')));

const UserProfile = Loadable(lazy(() => import('./views/apps/profile')));
const IndustralList = Loadable(lazy(() => import('./views/apps/industrial/List')));
const IndustralDetails = Loadable(lazy(() => import('./views/apps/industrial/Detail')));
const SiteData = Loadable(lazy(() => import('./views/apps/sites-data/List')));

const VendorProfile = Loadable(lazy(() => import('./views/apps/vendor-profile/VendorProfile')));
const UserSubscription = Loadable(lazy(() => import('./views/apps/user-subscription/List')));
const UserSubscriptionDetails = Loadable(lazy(() => import('./views/apps/user-subscription/Detail')));
const AddUserSubsciption = Loadable(lazy(() => import('./views/apps/user-subscription/AddUserSubsciption')));

// const Icons = Loadable(lazy(() => import('./views/icons/Icons')))
// const TypographyPage = Loadable(lazy(() => import('./views/utilities/TypographyPage')))
// const Shadow = Loadable(lazy(() => import('./views/utilities/Shadow')))
// const Error = Loadable(lazy(() => import('./views/authentication/Error')));
// const Register = Loadable(lazy(() => import('./views/authentication/Register')));
// const Login = Loadable(lazy(() => import('./views/authentication/Login')));

function App() {
  const [showModal, setShowModal, browser] = useMobileDetection();

  const { user, isSuperUser } = useAuth();
  console.log('user', user, isSuperUser);
  const theme = baselightTheme;

  console.log('browser', browser);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const Layout = () => {
    return (
      <>
        <LoginSignUpBox />
        <Header />
        <Outlet />
      </>
    );
  };

  const routers = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Main />,
        },

        {
          path: '/how-to-use',
          element: <HowToUse />,
        },
        {
          path: '/contact-us',
          element: <ContactUsComponent />,
        },
        {
          path: '/getstarted',
          element: <GettingStarted />,
        },
        {
          path: '/site-selection',
          element: <SitesSelection />,
        },

        {
          path: '/payment',
          element: <Payment />,
        },
        {
          path: '/profile',
          element: <PrivateRoute element={<Userprofile />} />,
        },
        // {
        //   path: '/vendor',
        //   element: <Vendor />,
        // },

        {
          path: '/lead-detail/:id/:read',
          element: <PrivateRoute element={<LeadsDetail />} />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
        {
          path: '/stripepayment',
          element: <CheckoutPage />,
        },
        {
          path: '/projection',
          element: <ProjectionPage />,
        },
        {
          path: '/valuation',
          element: <ValuationPage />,
        },
        {
          path: '/sitesuitability',
          element: <SiteSuitability />,
        },

        {
          path: '/user-aggrement',
          element: <PrivacyPolicy />,
        },
        {
          path: '/leads',
          element: <PrivateRoute element={<Leads />} />,
        },
        {
          path: '/vendor',
          element: <PrivateRoute element={<VendorPage />} />,
        },
        {
          path: '/team',
          element: <Team />,
        },

        {
          path: '/otp',
          element: <Otp />,
        },
        {
          path: '/wizard',
          element: (
            <AppProvider>
              <FormWizard />
            </AppProvider>
          ),
        },
        {
          path: '/submittedreports',
          element: <PrivateRoute element={<SubmittedReports />} />,
        },
      ],
    },
    {
      children: [
        {
          path: '/setpassword',
          element: <ResetPassword />,
        },
        {
          path: '/delete-user',
          element: <DeleteUser />,
        },
      ],
    },

    {
      path: '/dashboard',
      element: (
        <ProvideAuth>
          <FullLayout />
        </ProvideAuth>
      ),
      children: [
        { path: '/dashboard', element: isSuperUser ? <ModernDash /> : <Navigate to="/" /> },
        { path: '/dashboard/tickets', element: <Tickets /> },
        { path: '/dashboard/industrial', element: <IndustralList /> },
        { path: '/dashboard/industrial/details/', element: <IndustralDetails /> },
        { path: '/dashboard/vendor-user', element: <VendorProfile /> },
        { path: '/dashboard/site-data', element: <SiteData /> },
        { path: '/dashboard/user-subscription', exact: true, element: <UserSubscription /> },
        { path: '/dashboard/user-subscription/details', exact: true, element: <UserSubscriptionDetails /> },
        { path: '/dashboard/user-subscription/add-user-subscription', exact: true, element: <AddUserSubsciption /> },
         
      ],
    },
  ]);

  return (
    <div className="App">
      <MobileModel show={showModal} handleClose={handleCloseModal}>
        <p>You can use our mobile app for a better experience!</p>
        <a
          href={
            browser === 'safari'
              ? 'https://apps.apple.com/us/app/tezintel/id6470182263'
              : 'https://play.google.com/store/apps/details?id=com.tezIntelMobile'
          }
          target="_blank"
          className="button"
        >
          Download the App
        </a>
      </MobileModel>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RemainingReportProvider>
          <RouterProvider router={routers} />
        </RemainingReportProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
