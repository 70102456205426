import { useEffect, useState } from 'react';
import { Button, Col, Form, FormFeedback, Label, Row, e } from 'reactstrap';
import { WrappedInput } from './WrappedInput';
import { useForm } from 'react-hook-form';

import { useAuth } from '../hooks/useAuth';
import Emitter from './Emitter';
import { useNavigate } from 'react-router-dom';
import { decrypt, encrypt } from '../utils/common';
import { toast } from 'react-toastify';
import { AllRegex } from '../validation';

function LoginBox({ setCurrentActiveTab, currentActiveTab, cb }) {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  const {
    register,
    setError,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
    reset,
  } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const loginSubmit = (data) => {
    const req = data;
    delete req['passwordwrong'];
    setIsSubmitting(true);
    signIn(req)
      .then(function (response) {
        if (data.rememberMe) {
          const encryptedPassword = encrypt(data.password);
          localStorage.setItem('rememberMe', data.rememberMe);
          localStorage.setItem('email', watch('email'));

          localStorage.setItem('password', encryptedPassword);
          localStorage.setItem('user-email', email);
        } else {
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('email');
          localStorage.removeItem('password');
        }
        reset();
        
        

        if (response?.user_type === 'VENDOR') {
          navigate('/leads');
        } else if (response?.is_superuser) {
          navigate('/dashboard');
          // navigate('/vendor');
        }
        Emitter.emit('userLoggedIn');
        toast.info('User Successfully Logged In');
        cb?.();
      })
      .catch(function (error) {
        if (error.response.data?.is_verified === false) {
          const userId = error.response.data?.userid;
          const phone_number = error.response.data?.phone_number;
          const email = error.response.data?.email;

          if (userId && phone_number && email) {
            Emitter.emit('userLoggedIn');
            toast.warning('Please verify your account via OTP.');
            navigate('/otp', {
              state: {
                userId,
                phone_number,
                email,
              },
            });
          } else {
            console.error('Required fields for OTP are missing.');
          }
          return;
        } else {
          setError('passwordwrong', { type: 'custom', message: error.response?.data?.detail }, { shouldFocus: false });
        }
        // setError('passwordwrong', { type: 'custom', message: error.response.data.detail }, { shouldFocus: false });
      })
      .finally(function () {
        setIsSubmitting(false);
      });
  };

  const toggleLoginModalTab = () => {
    setCurrentActiveTab('3');
  };

  useEffect(() => {
    let isRerememberMe = localStorage.getItem('rememberMe');

    if (isRerememberMe == 'true') {
      let email = localStorage.getItem('email');
      let encryptedPassword = localStorage.getItem('password');

      const password = decrypt(encryptedPassword);

      setValue('email', email);
      setValue('password', password);
      setValue('rememberMe', true);
    }
  }, []);

  const email = watch('email');

  useEffect(() => {
    if (email !== undefined) {
      trigger('email');
    }
  }, [trigger, email]);

  return (
    <div style={{ position: 'relative', zIndex: '99' }} className="loginBox">
      <Form
        onSubmit={handleSubmit(loginSubmit)}
        // className={"row needs-validation ms-1 me-1"}
      >
        <Row>
          <Col>
            <Label for="username">Email</Label>
            <WrappedInput
              type="email"
              name="email"
              placeholder="Email"
              className={'form-control form-control-lg  signUpField  '}
              invalid={errors?.email}
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: AllRegex.email,
                  message: 'Please enter a valid email address',
                },
              })}
              onChange={(e) => {
                setValue('email', e.target.value);
              }}
              onFocus={() => {
                trigger('email');
              }}
              onBlur={() => {
                trigger('email');
              }}
            />
            {errors?.email && <FormFeedback> {errors?.email?.message}</FormFeedback>}
          </Col>
        </Row>
        <Row className={'mt-3'}>
          <Col>
            <Label for="password">Password</Label>
            <WrappedInput
              type="password"
              placeholder="Password"
              name="password"
              className={'form-control form-control-lg signUpField'}
              invalid={errors?.password}
              {...register('password', {
                required: 'Password is required',
              })}
              onChange={(e) => {
                setValue('password', e.target.value);
                trigger('password');
              }}
              onFocus={() => {
                trigger('password');
              }}
              onBlur={() => {
                trigger('password');
              }}
            />
            {errors?.password && <FormFeedback> {errors?.password?.message}</FormFeedback>}
            <WrappedInput
              type="hidden"
              name="passwordwrong"
              id="passwordwrong"
              invalid={errors?.passwordwrong}
              {...register('passwordwrong', { disabled: true })}
            />
            {errors?.passwordwrong && <FormFeedback> {errors?.passwordwrong?.message}</FormFeedback>}

            <div className="form-group mt-2 d-flex justify-content-between">
              <div className="d-flex align-items-center gap-1 ">
                <input
                  type="checkbox"
                  id="Remember Me"
                  name="Remember Me"
                  checked={watch('rememberMe')}
                  onChange={(e) => {
                    console.log('eeee', e.target.checked);
                    if (e.target.checked) {
                      setValue('rememberMe', true);
                    } else {
                      setValue('rememberMe', false);
                    }
                  }}
                />{' '}
                <label
                  className=""
                  style={{
                    color: '#0497FE',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  Remember Me
                </label>
              </div>

              <div>
                <a
                  className={'cursor-pointer'}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    toggleLoginModalTab('3');
                  }}
                  style={{
                    color: '#0497FE',
                    fontSize: '12px',
                    fontWeight: '600',
                  }}
                >
                  Forgot password?{' '}
                </a>
              </div>
            </div>

            <div className="text-center mt-3">
              <Button type="submit" className={'w-100 loginBtn'} disabled={isSubmitting}>
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                Login
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LoginBox;
