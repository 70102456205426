import { AUTH_CONSTANTS, URL_CONSTANTS } from '../store/constant';
import axios from 'axios';
import { toast } from 'react-toastify';
const axiosUnSecureInstance = axios.create({
  baseURL: URL_CONSTANTS.WEB_SERVERURL,
});

const axiosInstance = axios.create({
  baseURL: URL_CONSTANTS.WEB_SERVERURL,
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_CONSTANTS.ACCESS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.headers['Content-Type'] === undefined) config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

 
axiosInstance.interceptors.response.use(
  (response) => response,  
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(AUTH_CONSTANTS.ACCESS_TOKEN);
      localStorage.removeItem(AUTH_CONSTANTS.REFRESH_TOKEN);  
      window.location.href = '/';  
      toast.info("Session Expired");
    }
    return Promise.reject(error);
  }
);

export { axiosUnSecureInstance };

export default axiosInstance;
