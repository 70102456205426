import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import './assets/css/myprofile.css';
import './assets/css/vendor.css';
import './assets/css/leaddetails.css';
import './assets/css/submittedreport.css';
import './assets/css/error.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.min.css';

import { ProvideAuth } from './hooks/useAuth';

import { ToastContainer } from 'react-toastify';
import { ServiceProvider } from './hooks/State';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { baselightTheme } from "./theme/DefaultColors";
import { Provider } from 'react-redux';
import { store } from './store/Store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <ProvideAuth>
      <ServiceProvider>
        <ToastContainer autoClose={1000} />
        <App />
      </ServiceProvider>
    </ProvideAuth>
    </Provider>
    
  </React.StrictMode>
);

reportWebVitals();
