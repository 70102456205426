import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../services/axio-service';

const initialState = {
  projects: [],
  loading: false, // Add a loading state
  error: null,    // Optional: Add an error state for better error handling
};

export const ProjectSlice = createSlice({
  name: 'Project Slice',
  initialState,
  reducers: {
    fetchProjectsStart: (state) => {
      state.loading = true;
      state.error = null; // Reset error state if previously set
    },
    fetchProjectsSuccess: (state, action) => {
      state.loading = false;
      state.projects = action.payload;
    },
    fetchProjectsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload; // Capture the error if needed
    },
  },
});

export const { fetchProjectsStart, fetchProjectsSuccess, fetchProjectsFailure } = ProjectSlice.actions;

// Thunk for fetching projects
export const fetchProjects = () => async (dispatch) => {
  dispatch(fetchProjectsStart()); // Set loading to true
  try {
    const response = await axiosInstance.get('admindashboard/project-detail/');
    dispatch(fetchProjectsSuccess(response.data)); // Update projects and stop loading
  } catch (err) {
    dispatch(fetchProjectsFailure(err.message || 'Failed to fetch projects')); // Handle error
  }
};

export default ProjectSlice.reducer;
