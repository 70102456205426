import { createSlice } from '@reduxjs/toolkit';
import { filter, map } from 'lodash';
import axiosInstance from '../../../services/axio-service';

const initialState = {
  products: [],
  product: {},
  productSearch: '',
  productSearchByEmail: '',
  siteData: [],
  users: [],
  error: '',
  isProductUpdated: false,
  user_subscriptions: [],
  user_subscription: {},
  is_subscription_updated: false,
  is_user_subscription_added: false,
  is_user_subscription_added_message: '',
};

const SiteSlice = createSlice({
  name: 'industrialSite',
  initialState,
  reducers: {
    // Handle errors
    hasError(state, action) {
      state.error = action.payload;
    },
    // Get all products
    getProducts(state, action) {
      state.products = action.payload;
    },

    allUsers(state, action) {
      state.users = action.payload;
    },
    // Get a single product
    getProduct(state, action) {
      state.product = action.payload;
    },

    getSiteData(state, action) {
      state.siteData = action.payload;
    },

    // Search product
    SearchProduct(state, action) {
      state.productSearch = action.payload;
    },

    filterByEmail(state, action) {
      const email = action.payload.toLowerCase(); // Normalize email for case-insensitive search
      state.productSearchByEmail = email;
      state.products = state.products.filter((product) => product.user?.email?.toLowerCase().includes(email));
    },

    // Reset the email filter
    resetEmailFilter(state) {
      state.productSearchByEmail = '';
    },

    addUserSubscriptionsFn(state, action) {
      state.is_user_subscription_added = action.payload;
    },
    getUserSubscriptions(state, action) {
      state.user_subscriptions = action.payload;
    },

    getUserSubscription(state, action) {
      state.user_subscription = action.payload;
    },

    userSubscriptionUpdated(state, action) {
      state.is_subscription_updated = true;
    },
    resetUserSubscriptionUpdated(state) {
      state.is_subscription_updated = false;
    },
  },
});

export const {
  hasError,
  getProducts,
  getProduct,
  SearchProduct,
  setProductUpdated,
  resetProductUpdated,
  resetEmailFilter,
  filterByEmail,
  allUsers,
  getSiteData,
  getUserSubscriptions,
  getUserSubscription,
  userSubscriptionUpdated,
  resetUserSubscriptionUpdated,
  addUserSubscriptionsFn,
} = SiteSlice.actions;

// Thunk to fetch all products
export const fetchProducts = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`admindashboard/industrial-sites/`);
    dispatch(getProducts(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const fetchGetSiteData = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`admindashboard/sites/`);
    dispatch(getSiteData(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const fetchAllUser = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`admindashboard/all-users/`);
    dispatch(allUsers(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

// Thunk to fetch a single product by ID
export const getProductSlice = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`admindashboard/industrial-sites/${id}`);
    dispatch(getProduct(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

// Thunk to update a product
export const updateProductSlice = (payload) => async (dispatch) => {
  try {
    await axiosInstance.put(`admindashboard/industrial-sites/${payload.id}/`, payload);
    dispatch(setProductUpdated()); // Set the flag to true on success
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

// User Subscription

export const getUserSubscriptionSlice = (id) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`admindashboard/user-subscriptions/${id}`);
    console.log('subs', response.data);
    dispatch(getUserSubscription(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const fetchUserSubscriptions = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`admindashboard/user-subscriptions/`);

    dispatch(getUserSubscriptions(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const updateUserSubscriptions = (payload) => async (dispatch) => {
  console.log('paaa', payload);
  try {
    const response = await axiosInstance.put(`admindashboard/user-subscriptions/${payload.id}/`, payload);

    dispatch(userSubscriptionUpdated());
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const addUserSubscriptionsSlice = (payload) => async (dispatch) => {
  console.log('paaa', payload);
  try {
    const response = await axiosInstance.post(`admindashboard/user-subscriptions/`, payload);

    dispatch(addUserSubscriptionsFn(true));
  } catch (error) {
    console.log('error', error);
    dispatch(hasError(error.response.data.message));
  }
};

export default SiteSlice.reducer;
