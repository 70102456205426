import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../services/axio-service';

const initialState = {
  tickets: { vendors: [] }, // Ensure 'vendors' is an array
  isUpdated: false,
};


export const VendorSlice = createSlice({
  name: 'Vendor Slice',
  initialState,
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
    },
    
    updateTicket: (state, action) => {
      const { vendor_id, is_vendor_approved } = action.payload;
      console.log("veee",vendor_id,is_vendor_approved)


      const ticketIndex = state.tickets.findIndex((ticket) => ticket.id === vendor_id);

      if (ticketIndex !== -1) {
        state.tickets[ticketIndex].is_vendor_approved = is_vendor_approved;
        state.isUpdated = true;
        
      } else {
        console.warn(`No ticket found with vendor_id: ${vendor_id}`);
      }
    },
      
    resetUpdateFlag: (state) => {
      state.isUpdated = false; // Reset the flag
    },
  },
});

export const { getTickets, updateTicket, setVisibilityFilter, resetUpdateFlag } = VendorSlice.actions;

// Fetch Tickets
export const fetchTickets = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('admindashboard/vendors/');
    console.log("response",response)
    dispatch(getTickets(response.data.vendors));
  } catch (err) {
    console.error('Error fetching tickets:', err);
  }
};

// Update Ticket
export const updateTicketStatus = (ticketData) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('admindashboard/vendors/approve', ticketData);
    if (response.status === 200) {
      console.log("ticketData",ticketData)
      dispatch(updateTicket(ticketData));
    }
  } catch (err) {
    console.error('Error updating ticket status:', err);
  }
};

export default VendorSlice.reducer;
