// import axiosServices from '../../../utils/axios';
 
import axiosInstance from '../../../services/axio-service';
import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';

// const API_URL = '/api/data/ticket/TicketData';

const initialState = {
  statistics: {},
};

export const StatisticsSlice = createSlice({
  name: ' Statistics',
  initialState,
  reducers: {
    getStatistics: (state, action) => {
      state.statistics = action.payload;
    },
  },
});

export const { getStatistics } = StatisticsSlice.actions;

export const fetchStatistics = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`admindashboard/home/`);
    console.log("resss",response,response.data)
    dispatch(getStatistics(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export default StatisticsSlice.reducer;
